body {
  margin: 0;
  font-family: 'Harbourfront Body Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Harbourfront Header';
  src: url(./assets/fonts/Font-Header-Roboto/Roboto-Light.woff) format('woff');
}

@font-face {
  font-family: 'Harbourfront Body Regular';
  src: url(./assets/fonts/Font-Body-Harbourfront/Harbourfront-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Harbourfront Body Bold';
  src: url(./assets/fonts/Font-Body-Harbourfront/Harbourfront-Bold.woff) format('woff');
}
